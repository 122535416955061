import React from 'react'

export default ({title}) => {
    return (
      <h2 className="text-center my-5 position-relative">
          <span data-title={title}>
            {title}
          </span>
      </h2>
    )
}