import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Section from 'gatsby-theme-psg/src/components/Section'
import Header from '../components/Header'
import Lineup from 'gatsby-theme-psg/src/components/Lineup'
import Index from 'gatsby-theme-psg/src/components/Gallery'
import Tickets from 'gatsby-theme-psg/src/components/Tickets'
import Img from "gatsby-image"
import Summary from "gatsby-theme-psg/src/components/Events/Summary";

export const IndexPageTemplate = ({ images, logo }) => {
  let normalizedProps = logo
  if (normalizedProps.fluid && normalizedProps.fluid.presentationWidth) {
    normalizedProps = {
      ...normalizedProps,
      style: {
        ...(normalizedProps.style || {}),
        maxWidth: normalizedProps.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
      },
    }
  }
  return (
      <React.Fragment>
        <Header>
          <Img {...normalizedProps} fluid={logo.fluid} alt='logo'
               className="logo clearfix"
          />
          <Summary />
        </Header>
        <div className={"content"}>
          <Section
              title="Artiesten"
              className="acts"
              link="/lineup"
              linkName="Volledige line-up"
          >
            <Lineup numItems={5} />
          </Section>
          <Section
              title="Tickets"
              className="tickets"
              link="/tickets"
              linkName="Alle tickets"
          >
            <Tickets />
          </Section>
          {images && images.length > 0 &&
          <Section title="Impressie" className="impressie">
            <div className="d-none d-md-block">
              <Index images={images} imagesPerPage="3" />
            </div>
            <div className="d-block d-md-none">
              <Index images={images} imagesPerPage="1" />
            </div>
          </Section>
          }
        </div>
      </React.Fragment>
  )
}

const IndexPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
      <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
        <IndexPageTemplate images={post.frontmatter.images} logo={data.file.childImageSharp} />
      </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPage {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 354, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        description
        images {
          image {
            childImageSharp {
              fluid(maxWidth: 900, maxHeight: 600, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
        }
      }
    }
  }
`
