import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import { Card, Row, Col } from 'react-bootstrap';
import {lineupParams} from "gatsby-theme-psg/src/params";

export default ({ act }) => {
    const artist = act.artist
    return (
        <Row>
            <Col>
                <Card {...lineupParams.artist.cardProps}>
                    {act.announced && artist.frontmatter.image
                        ?
                        <Card.Img as={Img} fluid={artist.frontmatter.image.childImageSharp.fluid}
                             alt={artist.frontmatter.title}
                        />
                        :
                        <div style={{ width: '100%', paddingBottom: '100%' }}/>
                    }
                </Card>
            </Col>
            <Col className={"p-0 align-self-center"}>
                <h3>
                    {artist.frontmatter.title}
                </h3>
            </Col>
        </Row>
    )
}

export const query = graphql`
    fragment Artist on MarkdownRemark {
        id
        html
        frontmatter {
            title
            templateKey
            image {
                childImageSharp {
                    fluid(maxWidth: 800, maxHeight: 600, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    }
`