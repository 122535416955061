import React from 'react'
import Act from './Act';
import {Row, Col} from 'react-bootstrap'
import {lineupParams} from "gatsby-theme-psg/src/params";

const Stage = ({numItems=null, acts}) => {
  return (
    <React.Fragment>
      {acts && acts.length
        ?
        <Row className={"acts"}>
          {acts.slice(0, numItems ? numItems : acts.length ).map(( act, index ) => (
            <Col {...lineupParams.colProps} key={index} number={index}>
              <Act act={act} />
            </Col>
          ))}
        </Row>
        :
        <h3 className="text-center">{lineupParams.emptyText}</h3>
      }

    </React.Fragment>
  )
}

export default Stage

